import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';

type Meta = {
  name: string;
  content: string;
};

type Props = {
  title: string;
  description?: string;
  lang?: string;
  meta?: Meta[];
  keywords?: string[];
};

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

const render = (props: Props, data) => {
  const metaDescription = props.description || data.site.siteMetadata.description;
  return (
    <Helmet
      htmlAttributes={{
        lang: props.lang
      }}
      title={props.title}
      titleTemplate={`%s | ${data.site.siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: props.title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: data.site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: props.title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'viewport',
          content: 'initial-scale=1.0, width=device-width',
        },
      ].concat(
        (props.keywords as []).length > 0
          ? {
            name: 'keywords',
            content: props.keywords?.join(', '),
          }
          : []
      ).concat(props.meta || [])}
    />
  );
};

const SEO: React.FC<Props> = (props) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => render(props, data)}
    />
  );
};

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
};

export default SEO;
